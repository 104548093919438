import { ComponentProps } from 'react';
import Svg from '../app/Svg';

const IconDeliveryMan = (props: ComponentProps<typeof Svg>) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 435.204 361.137"
    {...props}
  >
    <path
      data-name="Path 115"
      d="M63.731 327.837c85 44.4 222.75 44.4 307.73 0s85-116.36 0-160.76-222.75-44.39-307.73 0-84.97 116.37 0 160.76z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 116"
      d="m250.091 65.027-24.37-14.07-24.36 14.07 24.36 14.07z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 117"
      d="m225.721 107.247 24.37-14.07v-28.15l-24.37 14.07z"
      fill="#ebebeb"
    />
    <path
      data-name="Path 118"
      d="M230.421 97.907a.75.75 0 0 1-.37-.09 1.13 1.13 0 0 1-.46-1v-3.22h.15l2.34-1.37v3.33a2.68 2.68 0 0 1-1.15 2.18 1 1 0 0 1-.51.17zm-.45-3.87v2.76a.77.77 0 0 0 .27.69.49.49 0 0 0 .5-.06 2.34 2.34 0 0 0 1-1.85v-.24h-.87l-.17-1.17zm1.2.92h.54v-2.05l-1.4.81.69.13z"
      fill="#fff"
    />
    <path
      data-name="Path 119"
      d="M230.841 100.287a.19.19 0 0 1-.19-.19v-2.5a.18.18 0 0 1 .19-.19.19.19 0 0 1 .19.19v2.46a.2.2 0 0 1-.19.23z"
      fill="#fff"
    />
    <path
      data-name="Path 120"
      d="M229.751 100.917a.18.18 0 0 1-.16-.1.17.17 0 0 1 .07-.25l2.17-1.26a.19.19 0 0 1 .26.07.2.2 0 0 1-.07.26l-2.18 1.25z"
      fill="#fff"
    />
    <path
      data-name="Path 121"
      d="M237.511 96.597a.36.36 0 0 1-.21 0 .5.5 0 0 1-.22-.45v-.51a.185.185 0 1 1 .37 0v.54h.12a1.07 1.07 0 0 0 .35-.84v-3.28a.185.185 0 0 1 .37 0v3.34a1.45 1.45 0 0 1-.53 1.17.67.67 0 0 1-.25.03z"
      fill="#fff"
    />
    <path
      data-name="Path 122"
      d="M235.531 93.687v-.32a5.33 5.33 0 0 1 2.4-4.16 1.59 1.59 0 0 1 2.59 1.49v.11zm3.52-4.44a1.82 1.82 0 0 0-.93.29 5 5 0 0 0-2.2 3.49l4.22-2.43a1.38 1.38 0 0 0-.58-1.22 1 1 0 0 0-.51-.13z"
      fill="#fff"
    />
    <path
      data-name="Path 123"
      d="M244.201 92.757a.19.19 0 0 1-.19-.18v-6.38a.19.19 0 0 1 .38 0v6.38a.19.19 0 0 1-.19.18z"
      fill="#fff"
    />
    <path
      data-name="Path 124"
      d="M243.691 87.647h-.07a.19.19 0 0 1-.1-.25l.67-1.54.74.86a.184.184 0 1 1-.28.24l-.36-.4-.43 1a.18.18 0 0 1-.17.09z"
      fill="#fff"
    />
    <path
      data-name="Path 125"
      d="M245.971 91.777a.19.19 0 0 1-.18-.19v-6.38a.19.19 0 1 1 .37 0v6.38a.19.19 0 0 1-.19.19z"
      fill="#fff"
    />
    <path
      data-name="Path 126"
      d="M245.421 86.657h-.07a.19.19 0 0 1-.1-.25l.67-1.54.74.86a.19.19 0 0 1 0 .26.2.2 0 0 1-.27 0l-.35-.41-.43 1a.19.19 0 0 1-.19.08z"
      fill="#fff"
    />
    <path
      data-name="Path 127"
      d="m225.721 107.247-24.37-14.07.01-28.15 24.36 14.07z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 128"
      d="m214.861 72.827 24.35-14.08-2.75-1.59-24.36 14.08z"
      fill="#fff"
    />
    <path
      data-name="Path 129"
      d="m205.051 81.167 8.73 5a3.86 3.86 0 0 1 1.73 3v5.38c0 1.11-.78 1.56-1.73 1l-8.73-5a3.85 3.85 0 0 1-1.73-3v-5.38c0-1.11.78-1.55 1.73-1z"
      fill="#004e89"
    />
    <path
      data-name="Path 130"
      d="m204.381 82.507.33.2v5.97l-.33-.19z"
      fill="#004e89"
    />
    <path
      data-name="Path 131"
      d="m205.731 83.287.57.34v5.97l-.57-.33z"
      fill="#004e89"
    />
    <path
      data-name="Path 132"
      d="m207.081 84.067.33.19v5.98l-.33-.19z"
      fill="#004e89"
    />
    <path
      data-name="Path 133"
      d="m208.091 84.647.67.39v5.98l-.67-.4z"
      fill="#004e89"
    />
    <path
      data-name="Path 134"
      d="m209.771 85.627.34.19v5.98l-.34-.2z"
      fill="#004e89"
    />
    <path
      data-name="Path 135"
      d="m210.751 86.177.7.42v5.97l-.71-.42z"
      fill="#004e89"
    />
    <path
      data-name="Path 136"
      d="m212.471 87.187.33.19v5.97l-.33-.19z"
      fill="#004e89"
    />
    <path
      data-name="Path 137"
      d="m213.241 87.617.17.1v5.97l-.17-.09z"
      fill="#004e89"
    />
    <path
      data-name="Path 138"
      d="m211.861 86.857.16.1v5.97l-.16-.09z"
      fill="#004e89"
    />
    <path
      data-name="Path 139"
      d="m209.141 85.267.16.09v5.98l-.16-.1z"
      fill="#004e89"
    />
    <path
      data-name="Path 140"
      d="m207.651 84.407.17.1v5.98l-.17-.1z"
      fill="#004e89"
    />
    <path
      data-name="Path 141"
      d="m206.581 83.817.17.1v5.98l-.17-.1z"
      fill="#004e89"
    />
    <path
      data-name="Path 142"
      d="m205.131 82.937.17.1v5.98l-.17-.1z"
      fill="#004e89"
    />
    <path
      data-name="Path 143"
      d="m213.821 87.957.33.19v5.98l-.33-.19z"
      fill="#004e89"
    />
    <path
      data-name="Path 144"
      d="m217.601 60.337-28.44-16.41-28.43 16.41 28.43 16.42z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 145"
      d="m176.971 69.717 28.44-16.41-2.76-1.59-28.43 16.41z"
      fill="#fff"
    />
    <path
      data-name="Path 146"
      d="m189.161 128.347 28.44-16.41v-51.6l-28.44 16.42z"
      fill="#ebebeb"
    />
    <path
      data-name="Path 147"
      d="M193.861 119.057a.69.69 0 0 1-.37-.1 1.1 1.1 0 0 1-.46-1v-3.22h.16l2.33-1.37v3.33a2.7 2.7 0 0 1-1.15 2.18 1 1 0 0 1-.51.18zm-.45-3.87v2.76a.77.77 0 0 0 .27.69.49.49 0 0 0 .5-.06 2.34 2.34 0 0 0 1-1.85v-.24h-.87l-.17-1.17zm1.2.92h.59v-2.05l-1.4.82.69.12z"
      fill="#fff"
    />
    <path
      data-name="Path 148"
      d="M194.281 121.397a.19.19 0 0 1-.19-.19v-2.51a.18.18 0 0 1 .19-.18.19.19 0 0 1 .19.18v2.51a.2.2 0 0 1-.19.19z"
      fill="#fff"
    />
    <path
      data-name="Path 149"
      d="M193.201 122.057a.18.18 0 0 1-.16-.09.2.2 0 0 1 .07-.26l2.17-1.25a.19.19 0 0 1 .26.07.18.18 0 0 1-.07.25l-2.18 1.26a.15.15 0 0 1-.09.02z"
      fill="#fff"
    />
    <path
      data-name="Path 150"
      d="M200.951 117.707a.46.46 0 0 1-.21-.05.5.5 0 0 1-.22-.45v-.52a.185.185 0 0 1 .37 0v.64c0 .01.06 0 .12 0a1.09 1.09 0 0 0 .35-.85v-3.33a.19.19 0 0 1 .19-.19.2.2 0 0 1 .19.19v3.33a1.41 1.41 0 0 1-.54 1.17.57.57 0 0 1-.25.06z"
      fill="#fff"
    />
    <path
      data-name="Path 151"
      d="M198.971 114.797v-.33a5.32 5.32 0 0 1 2.4-4.15 1.59 1.59 0 0 1 2.59 1.49v.11zm3.52-4.45a1.92 1.92 0 0 0-.93.29 5 5 0 0 0-2.2 3.5l4.22-2.43a1.41 1.41 0 0 0-.58-1.23 1.07 1.07 0 0 0-.51-.13z"
      fill="#fff"
    />
    <path
      data-name="Path 152"
      d="M207.681 113.867a.19.19 0 0 1-.19-.19v-6.38a.19.19 0 1 1 .38 0v6.38a.19.19 0 0 1-.19.19z"
      fill="#fff"
    />
    <path
      data-name="Path 153"
      d="M207.131 108.757h-.07a.18.18 0 0 1-.1-.24l.67-1.54.74.86a.184.184 0 0 1-.28.24l-.36-.41-.43 1a.18.18 0 0 1-.17.09z"
      fill="#fff"
    />
    <path
      data-name="Path 154"
      d="M209.411 112.877a.18.18 0 0 1-.18-.18v-6.38a.185.185 0 1 1 .37 0v6.38a.18.18 0 0 1-.19.18z"
      fill="#fff"
    />
    <path
      data-name="Path 155"
      d="M208.871 107.767h-.08a.2.2 0 0 1-.1-.25l.67-1.54.75.86a.2.2 0 0 1 0 .26.19.19 0 0 1-.27 0l-.35-.41-.43 1a.18.18 0 0 1-.19.08z"
      fill="#fff"
    />
    <path
      data-name="Path 156"
      d="m189.161 128.347-28.43-16.41v-51.6l28.43 16.42z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 157"
      d="m166.511 101.207 8.72 5a3.85 3.85 0 0 1 1.74 3v5.39c0 1.1-.78 1.55-1.74 1l-8.72-5a3.82 3.82 0 0 1-1.73-3v-5.38c0-1.16.78-1.56 1.73-1.01z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 158"
      d="m165.841 102.547.33.2v5.97l-.33-.19z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 159"
      d="m167.191 103.327.57.34v5.98l-.57-.34z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 160"
      d="m168.531 104.107.34.19-.01 5.98-.33-.19z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 161"
      d="m169.551 104.687.66.39v5.98l-.66-.4z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 162"
      d="m171.231 105.667.33.19v5.98l-.33-.2z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 163"
      d="m172.201 106.217.71.42v5.97l-.71-.42z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 164"
      d="m173.931 107.217.33.2v5.97l-.33-.19z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 165"
      d="m174.701 107.657.17.1v5.98l-.17-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 166"
      d="m173.321 106.897.16.1v5.97l-.16-.09z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 167"
      d="m170.601 105.307.16.09v5.98l-.16-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 168"
      d="m169.111 104.447.16.1v5.98l-.16-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 169"
      d="m168.041 103.857.17.1v5.98l-.17-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 170"
      d="m166.591 102.977.17.1v5.98l-.17-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 171"
      d="m175.281 107.997.33.19v5.98l-.33-.19z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 172"
      d="m174.221 74.787 2.75 1.59v-6.66l-2.75-1.59z"
      fill="#f0f0f0"
    />
    <path
      data-name="Path 173"
      d="m132.291 114.277-20.31-11.72-28.43 16.41 20.31 11.73z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 174"
      d="m103.861 177.597 28.43-16.41v-46.91l-28.43 16.42z"
      fill="#ebebeb"
    />
    <path
      data-name="Path 175"
      d="M108.551 168.267a.65.65 0 0 1-.36-.1 1.11 1.11 0 0 1-.46-1v-3.22h.15l2.34-1.37v3.33a2.7 2.7 0 0 1-1.15 2.18 1.06 1.06 0 0 1-.52.18zm-.44-3.87v2.76a.75.75 0 0 0 .27.69.53.53 0 0 0 .5-.06 2.34 2.34 0 0 0 1-1.85v-.24h-.87l-.17-1.17zm1.2.92h.54v-2.06l-1.4.82.69.12z"
      fill="#fff"
    />
    <path
      data-name="Path 176"
      d="M108.981 170.647a.19.19 0 0 1-.19-.19v-2.51a.185.185 0 0 1 .37 0v2.51a.19.19 0 0 1-.18.19z"
      fill="#fff"
    />
    <path
      data-name="Path 177"
      d="M107.891 171.267a.17.17 0 0 1-.16-.09.18.18 0 0 1 .06-.26l2.18-1.25a.19.19 0 0 1 .26.07.18.18 0 0 1-.07.25l-2.18 1.26a.15.15 0 0 1-.09.02z"
      fill="#fff"
    />
    <path
      data-name="Path 178"
      d="M115.651 166.957a.46.46 0 0 1-.21-.05.5.5 0 0 1-.22-.45v-.52a.185.185 0 0 1 .37 0v.64c0 .01.06 0 .12 0a1.13 1.13 0 0 0 .35-.85v-3.33a.19.19 0 1 1 .37 0v3.33a1.44 1.44 0 0 1-.53 1.17.57.57 0 0 1-.25.06z"
      fill="#fff"
    />
    <path
      data-name="Path 179"
      d="M113.671 164.057v-.33a5.32 5.32 0 0 1 2.4-4.15 1.58 1.58 0 0 1 2.58 1.49v.11zm3.52-4.45a1.92 1.92 0 0 0-.93.29 5 5 0 0 0-2.2 3.5l4.22-2.44a1.4 1.4 0 0 0-.58-1.22 1.07 1.07 0 0 0-.5-.14z"
      fill="#fff"
    />
    <path
      data-name="Path 180"
      d="M122.381 163.117a.19.19 0 0 1-.19-.19v-6.38a.19.19 0 1 1 .38 0v6.38a.19.19 0 0 1-.19.19z"
      fill="#fff"
    />
    <path
      data-name="Path 181"
      d="M121.831 158.057h-.07a.18.18 0 0 1-.1-.24l.67-1.54.74.86a.184.184 0 1 1-.28.24l-.36-.41-.43 1a.18.18 0 0 1-.17.09z"
      fill="#fff"
    />
    <path
      data-name="Path 182"
      d="M124.111 162.127a.19.19 0 0 1-.19-.18v-6.38a.19.19 0 0 1 .19-.19.18.18 0 0 1 .19.19v6.38a.18.18 0 0 1-.19.18z"
      fill="#fff"
    />
    <path
      data-name="Path 183"
      d="M123.561 157.057a.1.1 0 0 1-.07 0 .18.18 0 0 1-.1-.24l.67-1.54.74.86a.187.187 0 0 1-.27.26l-.35-.41-.43 1a.19.19 0 0 1-.19.07z"
      fill="#fff"
    />
    <path
      data-name="Path 184"
      d="m103.861 177.597-20.31-11.72v-46.91l20.31 11.73z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 185"
      d="m92.981 131.077 2.75 1.59v-6.66l-2.75-1.59z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 186"
      d="m95.731 126.007 28.44-16.42-2.76-1.59-28.43 16.42z"
      fill="#fff"
    />
    <path
      data-name="Path 187"
      d="m95.731 154.147-36.55-21.1-36.56 21.1 36.56 21.11z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 188"
      d="m59.181 203.397 36.55-21.1v-28.15l-36.55 21.11z"
      fill="#ebebeb"
    />
    <path
      data-name="Path 189"
      d="M63.871 194.057a.75.75 0 0 1-.36-.1 1.12 1.12 0 0 1-.46-1v-3.22h.15l2.34-1.38v3.34a2.7 2.7 0 0 1-1.15 2.17 1.08 1.08 0 0 1-.52.19zm-.45-3.87v2.76a.74.74 0 0 0 .27.68.51.51 0 0 0 .51-.05 2.36 2.36 0 0 0 1-1.85v-.25h-.9l-.17-1.17zm1.2.92h.58v-2.05l-1.39.82.69.12z"
      fill="#fff"
    />
    <path
      data-name="Path 190"
      d="M64.291 196.437a.18.18 0 0 1-.18-.18v-2.51a.19.19 0 1 1 .37 0v2.51a.18.18 0 0 1-.19.18z"
      fill="#fff"
    />
    <path
      data-name="Path 191"
      d="M63.201 197.057a.19.19 0 0 1-.09-.35l2.18-1.25a.17.17 0 0 1 .25.06.18.18 0 0 1-.06.26l-2.18 1.28z"
      fill="#fff"
    />
    <path
      data-name="Path 192"
      d="M70.961 192.757a.46.46 0 0 1-.2 0 .5.5 0 0 1-.23-.46v-.51a.19.19 0 1 1 .38 0v.51a.21.21 0 0 0 0 .13h.13a1.13 1.13 0 0 0 .34-.85v-3.33a.2.2 0 0 1 .19-.19.19.19 0 0 1 .19.19v3.33a1.44 1.44 0 0 1-.53 1.17.58.58 0 0 1-.27.01z"
      fill="#fff"
    />
    <path
      data-name="Path 193"
      d="M68.991 189.847v-.33a5.27 5.27 0 0 1 2.4-4.15 1.58 1.58 0 0 1 2.58 1.49v.11zm3.52-4.45a2 2 0 0 0-.94.29 5 5 0 0 0-2.19 3.5l4.21-2.44a1.39 1.39 0 0 0-.57-1.22 1 1 0 0 0-.51-.13z"
      fill="#fff"
    />
    <path
      data-name="Path 194"
      d="M77.701 188.917a.18.18 0 0 1-.19-.19v-6.38a.19.19 0 1 1 .37 0v6.38a.18.18 0 0 1-.18.19z"
      fill="#fff"
    />
    <path
      data-name="Path 195"
      d="M77.201 183.807h-.08a.18.18 0 0 1-.09-.25l.66-1.54.75.86a.191.191 0 1 1-.29.25l-.35-.41-.43 1a.19.19 0 0 1-.17.09z"
      fill="#fff"
    />
    <path
      data-name="Path 196"
      d="M79.431 187.927a.19.19 0 0 1-.19-.19v-6.38a.19.19 0 1 1 .38 0v6.38a.19.19 0 0 1-.19.19z"
      fill="#fff"
    />
    <path
      data-name="Path 197"
      d="M78.881 182.817h-.07a.18.18 0 0 1-.1-.24l.67-1.55.74.86a.19.19 0 0 1 0 .27.18.18 0 0 1-.26 0l-.36-.41-.43 1a.18.18 0 0 1-.19.07z"
      fill="#fff"
    />
    <path
      data-name="Path 198"
      d="m59.181 203.397-36.56-21.1v-28.15l36.56 21.11z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 199"
      d="m39.521 170.567 2.75 1.59v-6.66l-2.75-1.59z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 200"
      d="m42.271 165.497 36.56-21.1-2.75-1.6-36.56 21.11z"
      fill="#fff"
    />
    <path
      data-name="Path 201"
      d="m28.411 171.567 8.72 5a3.85 3.85 0 0 1 1.74 3v5.39c0 1.1-.78 1.55-1.74 1l-8.72-5a3.82 3.82 0 0 1-1.73-3v-5.38c0-1.12.78-1.52 1.73-1.01z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 202"
      d="m27.741 172.907.33.19v5.98l-.33-.19z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 203"
      d="m29.081 173.687.58.34v5.97l-.58-.33z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 204"
      d="m30.431 174.467.34.19-.01 5.98-.33-.19z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 205"
      d="m31.451 175.047.67.39-.01 5.98-.66-.4z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 206"
      d="m33.131 176.027.33.19v5.98l-.33-.2z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 207"
      d="m34.101 176.577.71.42v5.97l-.71-.42z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 208"
      d="m35.831 177.587.33.19v5.97l-.33-.19z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 209"
      d="m36.601 178.017.17.1v5.97l-.17-.09z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 210"
      d="m35.221 177.257.16.09v5.98l-.16-.09z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 211"
      d="m32.501 175.667.16.09v5.98l-.16-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 212"
      d="m31.011 174.807.17.1v5.97l-.17-.09z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 213"
      d="m29.941 174.217.17.1v5.98l-.17-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 214"
      d="m28.491 173.337.17.1v5.97l-.17-.09z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 215"
      d="m37.181 178.357.33.2v5.97l-.33-.19z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 216"
      d="m87.611 97.867-28.43-16.42-28.44 16.42 28.44 16.41z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 217"
      d="m59.181 170.567 28.43-16.42v-56.28l-28.43 16.41z"
      fill="#ebebeb"
    />
    <path
      data-name="Path 218"
      d="M63.871 161.227a.74.74 0 0 1-.36-.09 1.12 1.12 0 0 1-.46-1v-3.22h.15l2.34-1.37v3.33a2.71 2.71 0 0 1-1.15 2.18 1.06 1.06 0 0 1-.52.17zm-.45-3.87v2.77a.76.76 0 0 0 .27.68.51.51 0 0 0 .51-.05 2.38 2.38 0 0 0 1-1.86v-.24h-.9l-.17-1.17zm1.2.92h.58v-2.05l-1.39.82.69.12z"
      fill="#fff"
    />
    <path
      data-name="Path 219"
      d="M64.291 163.607a.18.18 0 0 1-.18-.19v-2.5a.185.185 0 1 1 .37 0v2.5a.18.18 0 0 1-.19.19z"
      fill="#fff"
    />
    <path
      data-name="Path 220"
      d="M63.201 164.237a.19.19 0 0 1-.09-.35l2.18-1.26a.19.19 0 0 1 .19.33l-2.18 1.25a.17.17 0 0 1-.1.03z"
      fill="#fff"
    />
    <path
      data-name="Path 221"
      d="M70.961 159.927a.36.36 0 0 1-.2-.06.48.48 0 0 1-.23-.45v-.51a.19.19 0 1 1 .38 0v.51a.21.21 0 0 0 0 .13.24.24 0 0 0 .13 0 1.08 1.08 0 0 0 .34-.84v-3.33a.19.19 0 0 1 .19-.19.18.18 0 0 1 .19.19v3.33a1.44 1.44 0 0 1-.53 1.17.58.58 0 0 1-.27.05z"
      fill="#fff"
    />
    <path
      data-name="Path 222"
      d="M68.991 157.057v-.32a5.31 5.31 0 0 1 2.4-4.16 1.59 1.59 0 0 1 2.58 1.5v.1zm3.52-4.44a2 2 0 0 0-.94.29 5 5 0 0 0-2.19 3.49l4.21-2.43a1.37 1.37 0 0 0-.57-1.22 1 1 0 0 0-.51-.18z"
      fill="#fff"
    />
    <path
      data-name="Path 223"
      d="M77.701 156.057a.19.19 0 0 1-.19-.19v-6.38a.19.19 0 1 1 .37 0v6.38a.19.19 0 0 1-.18.19z"
      fill="#fff"
    />
    <path
      data-name="Path 224"
      d="M77.201 150.967h-.08a.18.18 0 0 1-.09-.25l.66-1.54.75.86a.19.19 0 0 1 0 .26.2.2 0 0 1-.27 0l-.35-.41-.43 1a.18.18 0 0 1-.19.08z"
      fill="#fff"
    />
    <path
      data-name="Path 225"
      d="M79.431 155.057a.2.2 0 0 1-.19-.19v-6.38a.19.19 0 1 1 .38 0v6.38a.2.2 0 0 1-.19.19z"
      fill="#fff"
    />
    <path
      data-name="Path 226"
      d="M78.881 149.987h-.07a.19.19 0 0 1-.1-.25l.67-1.54.74.86a.19.19 0 0 1 0 .27.2.2 0 0 1-.26 0l-.36-.41-.43 1a.19.19 0 0 1-.19.07z"
      fill="#fff"
    />
    <path
      data-name="Path 227"
      d="m59.181 170.567-28.44-16.42v-56.28l28.44 16.41z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 228"
      d="m44.231 112.307 2.76 1.59v-6.65l-2.76-1.6z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 229"
      d="m46.991 107.247 28.43-16.42-2.75-1.59-28.44 16.41z"
      fill="#fff"
    />
    <path
      data-name="Path 230"
      d="m36.531 143.417 8.72 5a3.81 3.81 0 0 1 1.73 3v5.38c0 1.1-.77 1.55-1.73 1l-8.72-5a3.82 3.82 0 0 1-1.74-3v-5.38c.01-1.1.78-1.55 1.74-1z"
      fill="#f5f5f5"
    />
    <path
      data-name="Path 231"
      d="m35.851 144.767.33.19v5.98l-.33-.2z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 232"
      d="m37.201 145.547.57.33v5.98l-.57-.34z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 233"
      d="m38.551 146.317.33.2v5.97l-.33-.19z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 234"
      d="m39.571 146.897.66.4v5.97l-.66-.39z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 235"
      d="m41.251 147.877.33.19v5.98l-.33-.19z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 236"
      d="m42.221 148.427.71.42v5.98l-.71-.42z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 237"
      d="m43.951 149.437.33.19v5.98l-.33-.2z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 238"
      d="m44.721 149.877.16.09v5.98l-.16-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 239"
      d="m43.331 149.107.17.1v5.98l-.17-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 240"
      d="m40.611 147.517.17.1v5.97l-.17-.09z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 241"
      d="m39.121 146.667.17.09v5.98l-.17-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 242"
      d="m38.061 146.077.16.09v5.98l-.16-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 243"
      d="m36.611 145.197.16.09v5.98l-.16-.1z"
      fill="#e0e0e0"
    />
    <path
      data-name="Path 244"
      d="m45.291 150.217.34.19v5.97l-.34-.19z"
      fill="#e0e0e0"
    />
    <g>
      <path
        data-name="Path 245"
        d="m31.011 252.807 109.14 63a9.57 9.57 0 0 0 8.66 0l239.13-138c2.39-1.38 2.39-3.62 0-5l-109.14-63a9.51 9.51 0 0 0-8.66 0l-239.13 138c-2.39 1.38-2.39 3.62 0 5z"
        fill="#455a64"
      />
      <path
        data-name="Path 246"
        d="M90.001 282.057a13.32 13.32 0 0 0 12 0c3.33-1.92 3.33-5 0-6.95a13.26 13.26 0 0 0-12 0c-3.33 1.95-3.33 5.05 0 6.95z"
        fill="#263238"
      />
      <path
        data-name="Path 247"
        d="m142.201 315.807-109.14-63c-2.14-1.24-2.36-3.16-.67-4.54a9.69 9.69 0 0 0-3.66 7v4.07a9.58 9.58 0 0 0 4.33 7.5l109.14 63a8.83 8.83 0 0 0 4.33 1v-13.99a8.83 8.83 0 0 1-4.33-1.04z"
        fill="#37474f"
      />
      <path
        data-name="Path 248"
        d="M387.931 191.827a9.56 9.56 0 0 0 4.34-7.5v-4.07a9.62 9.62 0 0 0-3.67-7c1.7 1.38 1.48 3.3-.66 4.53l-239.13 138.02a8.89 8.89 0 0 1-4.33 1v14.07a8.89 8.89 0 0 0 4.33-1z"
        fill="#263238"
      />
      <path
        data-name="Path 249"
        d="m158.131 302.757-104.46-60.34c-1.44-.83-1.44-2.18 0-3l201.92-116.61a5.74 5.74 0 0 1 5.2 0l104.5 60.33c1.43.83 1.43 2.17 0 3l-202 116.62a5.74 5.74 0 0 1-5.16 0z"
        fill="#fff"
      />
      <path
        data-name="Path 250"
        d="m116.041 269.067 12.19-7.04-4.06-2.34-12.19 7.03z"
        fill="#004e89"
      />
      <path
        data-name="Path 251"
        d="m262.281 184.637 12.19-7.04-4.07-2.34-12.18 7.04z"
        fill="#004e89"
      />
      <path
        data-name="Path 252"
        d="m286.651 170.567 12.19-7.04-4.06-2.34-12.19 7.03z"
        fill="#004e89"
      />
      <path
        data-name="Path 253"
        d="M325.771 145.917a3.41 3.41 0 0 0 3.05 0 .93.93 0 0 0 0-1.76 3.35 3.35 0 0 0-3.05 0 .93.93 0 0 0 0 1.76z"
        fill="#263238"
      />
      <path
        data-name="Path 254"
        d="M330.641 159.727a6.74 6.74 0 0 0 6.09 0c1.68-1 1.68-2.55 0-3.52a6.74 6.74 0 0 0-6.09 0c-1.68.97-1.68 2.55 0 3.52z"
        fill="#263238"
      />
      <path
        data-name="Path 255"
        d="M326.341 153.607c1.12-.65 1.06-1.73-.13-2.42l-20-11.57a4.61 4.61 0 0 0-4.2-.08c-1.12.65-1.06 1.73.13 2.42l20.06 11.57a4.62 4.62 0 0 0 4.14.08z"
        fill="#263238"
      />
    </g>
    <g>
      <path
        data-name="Path 256"
        d="m292.201 106.687.68-.4a19 19 0 0 0-.68 5.24v2.44c0 8.76 10.51 31.14 14.38 39.06.4.83-4.91 6.48-4.91 6.48l6.31-3.64s15.77-13 15.77-23.68v-2.44c0-10.73-7.06-23.5-15.77-28.53-4.4-2.53-8.37-2.62-11.23-.7l-6 3.44zm7.89 8.19c0-5 3.53-7.07 7.89-4.55-.36 3.23 5.54 13.29 7.88 13.66 0 5-3.53 7.07-7.88 4.55a17.42 17.42 0 0 1-7.85-13.66z"
        fill="#004e89"
      />
      <path
        data-name="Path 257"
        d="M285.711 117.877c.18 10.72 16.46 41.63 16.46 41.63s15.56-13.22 15.39-23.94v-2.44c-.18-10.72-7.45-23.38-16.25-28.26s-15.78-.16-15.6 10.57zm7.9.78c-.08-5 3.42-7.13 7.81-4.69a17.43 17.43 0 0 1 8.11 13.53c.09 5-3.41 7.13-7.81 4.69a17.48 17.48 0 0 1-8.11-13.53z"
        fill="#004e89"
      />
      <path
        data-name="Path 258"
        d="M285.711 117.877c.18 10.72 16.46 41.63 16.46 41.63s15.56-13.22 15.39-23.94v-2.44c-.18-10.72-7.45-23.38-16.25-28.26s-15.78-.16-15.6 10.57zm7.9.78c-.08-5 3.42-7.13 7.81-4.69a17.43 17.43 0 0 1 8.11 13.53c.09 5-3.41 7.13-7.81 4.69a17.48 17.48 0 0 1-8.11-13.53z"
        opacity={0.1}
      />
    </g>
    <path
      data-name="Path 259"
      d="M133.201 260.847c10.11 5.82 33.81 1.59 52.94-9.46l52.55-30.33c19.13-11.05 26.43-24.71 16.3-30.52s-33.85-1.56-53 9.48l-52.45 30.29c-19.12 11.04-26.44 24.75-16.34 30.54z"
      fill="#f0f0f0"
    />
    <g>
      <path
        data-name="Path 260"
        d="M286.201 6.577h.05l8.65-5h.07c5.45-2.76 12.73-2 20.57 3.1 15.76 10.14 27.79 33.54 26.88 52.25-.46 9.33-4 15.73-9.47 18.55h-.05l-8.66 5h-.06c-5.45 2.77-12.74 2-20.59-3.09-15.75-10.15-27.78-33.54-26.87-52.25.48-9.34 4.07-15.74 9.48-18.56z"
        fill="#37474f"
      />
      <path
        data-name="Path 261"
        d="m324.681 80.217 8.25-4.8h.06c5.42-2.83 9-9.23 9.46-18.55.4-8.34-1.77-17.61-5.69-26.22l-8.66 5c3.93 8.61 6.1 17.88 5.69 26.23-.43 9.1-3.87 15.43-9.11 18.34z"
        fill="#263238"
      />
      <path
        data-name="Path 262"
        d="M303.631 77.377c-15.75-10.15-27.78-33.54-26.87-52.25s14.44-25.66 30.16-15.52 27.79 33.54 26.88 52.25-14.42 25.66-30.17 15.52z"
        fill="#455a64"
      />
      <path
        data-name="Path 263"
        d="M316.831 76.057c9.29.45 10.8-10 11-14.5.8-16.5-10.26-38-24.15-46.95a21 21 0 0 0-10-3.7c-9.28-.45-10.79 10-11 14.5-.8 16.5 10.25 38 24.15 47a21 21 0 0 0 10 3.7z"
        fill="#fafafa"
      />
      <path
        data-name="Path 264"
        d="M303.461 46.587a.87.87 0 0 0 .32-.06 1 1 0 0 0 .69-1v-23.83a1 1 0 0 0-2 0v20.93l-11.13-14.3a1.012 1.012 0 1 0-1.6 1.24l12.95 16.63a1 1 0 0 0 .77.39z"
        fill="#37474f"
      />
      <path
        data-name="Path 265"
        d="M309.201 64.217a.36.36 0 0 0 .14 0 .43.43 0 0 0 .29-.52l-5.61-18.89a.422.422 0 1 0-.81.24l5.6 18.89a.42.42 0 0 0 .39.28z"
        fill="#004e89"
      />
      <path
        data-name="Path 266"
        d="M303.781 40.797c-1.86-1.19-3.45-.37-3.55 1.83a7.62 7.62 0 0 0 3.16 6.14c1.85 1.2 3.44.38 3.55-1.82a7.67 7.67 0 0 0-3.16-6.15z"
        fill="#004e89"
      />
    </g>
    <g>
      <path
        data-name="Path 267"
        d="m140.421 254.997 12.18-7.04-4.06-2.34-12.18 7.03z"
        fill="#004e89"
      />
      <path
        data-name="Path 268"
        d="m164.791 240.927 12.19-7.04-4.07-2.34-12.18 7.03z"
        fill="#004e89"
      />
      <path
        data-name="Path 269"
        d="M159.781 238.057c0 5.88-4.12 13-9.21 16-2.71 1.57-5.15 1.62-6.83.44a6.07 6.07 0 0 1-2.31-4.43 11.215 11.215 0 0 0 1-.53c5.09-2.93 9.21-10.08 9.21-16a9.13 9.13 0 0 0-.08-1.31 6.22 6.22 0 0 1 5-.36c2 .84 3.22 3.01 3.22 6.19z"
        fill="#263238"
      />
      <path
        data-name="Path 270"
        d="m160.611 225.977-.17-.1c-2.56-1.36-6-1.11-9.87 1.11-7.85 4.53-14.22 15.55-14.22 24.62 0 4.56 1.61 7.75 4.21 9.23s6.11 1.23 10-1c7.85-4.53 14.21-15.56 14.21-24.63.01-4.56-1.57-7.75-4.16-9.23zm-10 28.06c-2.72 1.57-5.16 1.62-6.84.43-1.48-1-2.38-3-2.38-5.75 0-5.87 4.13-13 9.22-16 2.32-1.34 4.43-1.57 6-.88 1.94.83 3.16 3 3.16 6.2.01 5.92-4.11 13.07-9.2 16.02z"
        fill="#37474f"
      />
      <path
        data-name="Path 271"
        d="m157.081 232.107-.11-.06c-1.66-.89-3.91-.72-6.4.71-5.09 2.94-9.22 10.09-9.22 16 0 3 1 5 2.73 6s4 .8 6.49-.66c5.09-2.94 9.21-10.09 9.21-16 0-2.96-1.03-5.04-2.7-5.99zm-6.51 19.85c-2.18 1.26-4.14 1.3-5.5.34-1.18-.83-1.91-2.42-1.91-4.62a16.38 16.38 0 0 1 7.41-12.83 5.49 5.49 0 0 1 4.87-.71c1.56.67 2.54 2.41 2.54 5a16.35 16.35 0 0 1-7.41 12.82z"
        fill="#004e89"
      />
      <path
        data-name="Path 272"
        d="m189.161 226.847 12.19-7.03-4.06-2.35-12.19 7.04z"
        fill="#004e89"
      />
      <path
        data-name="Path 273"
        d="m213.541 212.777 12.18-7.03-4.06-2.35-12.19 7.04z"
        fill="#004e89"
      />
      <path
        data-name="Path 274"
        d="m237.911 198.707 12.18-7.03-4.06-2.35-12.19 7.04z"
        fill="#004e89"
      />
      <path
        data-name="Path 275"
        d="M177.261 133.277a1 1 0 0 1 1.8.63 3.63 3.63 0 0 1-1.09 3.12c-.8.69-1.61.41-1.8-.63a3.64 3.64 0 0 1 1.09-3.12z"
        fill="#004e89"
      />
      <path
        data-name="Path 276"
        d="m186.641 137.697-8.15-4.71-1.79 4.31 8.18 4.72z"
        fill="#004e89"
      />
      <path
        data-name="Path 277"
        d="M185.391 137.967c.8-.68 1.6-.4 1.8.64a3.65 3.65 0 0 1-1.1 3.11c-.8.69-1.61.41-1.8-.63a3.68 3.68 0 0 1 1.1-3.12z"
        fill="#004e89"
      />
      <path
        data-name="Path 278"
        d="M248.031 177.407v1.8c-1.62-.69-3.73-.46-6.05.88-5.09 2.94-9.22 10.09-9.22 16 0 2.74.9 4.72 2.38 5.75a14.731 14.731 0 0 1-1.29.82l-.47.24-1.41 5.31-8.16-4.55c-2.58-1.49-4.17-4.67-4.17-9.21 0-9.07 6.36-20.09 14.21-24.63 3.88-2.24 7.39-2.47 10-1l8 4.48.17.1z"
        fill="#455a64"
      />
      <path
        data-name="Path 279"
        d="m252.011 173.307-.17-.1c-2.56-1.36-6-1.11-9.87 1.11-7.85 4.53-14.22 15.55-14.22 24.62 0 4.56 1.61 7.75 4.21 9.23s6.11 1.23 10-1c7.85-4.53 14.21-15.56 14.21-24.63.03-4.56-1.58-7.75-4.16-9.23zm-10 28.06c-2.72 1.57-5.16 1.62-6.84.43-1.48-1-2.38-3-2.38-5.75 0-5.87 4.13-13 9.22-16 2.32-1.34 4.43-1.57 6.05-.88 1.94.83 3.16 3 3.16 6.2-.02 5.92-4.16 13.07-9.25 16z"
        fill="#37474f"
      />
      <path
        data-name="Path 280"
        d="M251.201 185.407c0 5.88-4.12 13-9.21 16-2.71 1.57-5.15 1.62-6.83.44a6.07 6.07 0 0 1-2.31-4.43 11.217 11.217 0 0 0 1-.53c5.09-2.93 9.21-10.08 9.21-16a9.128 9.128 0 0 0-.08-1.31 6.22 6.22 0 0 1 5-.36c1.98.84 3.22 2.99 3.22 6.19z"
        fill="#263238"
      />
      <path
        data-name="Path 281"
        d="m248.481 179.437-.11-.07c-1.66-.88-3.92-.72-6.4.72-5.09 2.94-9.22 10.08-9.22 16 0 3 1 5 2.73 6s4 .79 6.49-.67c5.09-2.93 9.21-10.08 9.21-16 .02-2.95-.98-5.02-2.7-5.98zm-6.51 19.84c-2.19 1.27-4.15 1.31-5.5.35-1.19-.83-1.91-2.42-1.91-4.62a16.38 16.38 0 0 1 7.41-12.83c1.86-1.08 3.56-1.27 4.86-.71 1.56.67 2.54 2.41 2.54 5a16.34 16.34 0 0 1-7.4 12.81z"
        fill="#004e89"
      />
      <path
        data-name="Path 282"
        d="m233.841 188.397 23-13.28c2.67-1.55-6-10.6-12.45-11.21a24.9 24.9 0 0 0-10.56 1s4.91 6.29 4.07 9.86-4.06 13.63-4.06 13.63z"
        fill="#004e89"
      />
      <path
        data-name="Path 283"
        d="M201.351 150.867s33.34 16.82 34.78 17.83 0 12.74-2.29 19.7-5.81 17.66-7.22 20.66-3.25 4.12-6.63 6.07l-24 13.87-32.49 3.96-12.9-7 3.36-21.11s34.53-18.37 39.26-21.1 18.72-15.54 8.13-32.88z"
        fill="#004e89"
      />
      <path
        data-name="Path 284"
        d="M201.351 150.867s33.34 16.82 34.78 17.83 0 12.74-2.29 19.7-5.81 17.66-7.22 20.66-3.25 4.12-6.63 6.07l-24 13.87-32.49 3.96-12.9-7 3.36-21.11s34.53-18.37 39.26-21.1 18.72-15.54 8.13-32.88z"
        opacity={0.2}
      />
      <path
        data-name="Path 285"
        d="M236.771 184.387c3-8.06 5.24-14.74 4.86-19.25-.46-5.35-2.46-9.72-11.28-11.92s-21.15-2.16-21.15-2.16l-3.75 2.17s25.36 14.7 28.24 16.3c1.76 1 3.64 5.41.19 18.88l.59.34c.29.18 1.34-1.77 2.3-4.36z"
        fill="#004e89"
      />
      <path
        data-name="Path 286"
        d="M236.771 184.387c3-8.06 5.24-14.74 4.86-19.25-.46-5.35-2.46-9.72-11.28-11.92s-21.15-2.16-21.15-2.16l-3.75 2.17s25.36 14.7 28.24 16.3c1.76 1 3.64 5.41.19 18.88l.59.34c.29.18 1.34-1.77 2.3-4.36z"
        fill="#fff"
        opacity={0.3}
      />
      <path
        data-name="Path 287"
        d="m217.561 160.247 4.1-2.34s-.24-14.21-6.22-17.66-26.28-1.1-26.28-1.1l-4.06 2.34z"
        fill="#004e89"
      />
      <path
        data-name="Path 288"
        d="M204.261 143.497c-1.95 1.13-1.95 2.95 0 4.07a7.78 7.78 0 0 0 7.06 0c1.94-1.12 1.94-2.95 0-4.07a7.84 7.84 0 0 0-7.06 0z"
        fill="#f0f0f0"
      />
      <path
        data-name="Path 289"
        d="M210.501 150.287c-1.37.8-1.37 2.09 0 2.88a5.49 5.49 0 0 0 5 0c1.38-.79 1.38-2.08 0-2.88a5.55 5.55 0 0 0-5 0z"
        fill="#f0f0f0"
      />
      <path
        data-name="Path 290"
        d="M194.791 141.687c-1.38.79-1.38 2.08 0 2.88a5.55 5.55 0 0 0 5 0c1.38-.8 1.38-2.09 0-2.88a5.49 5.49 0 0 0-5 0z"
        fill="#f0f0f0"
      />
      <path
        data-name="Path 291"
        d="M218.201 156.127c.8-.69 1.6-.41 1.8.63a3.67 3.67 0 0 1-1.1 3.12 1 1 0 0 1-1.8-.63 3.63 3.63 0 0 1 1.1-3.12z"
        fill="#004e89"
      />
      <path
        data-name="Path 292"
        d="m227.531 160.547-8.16-4.71-1.78 4.31 8.17 4.71z"
        fill="#004e89"
      />
      <path
        data-name="Path 293"
        d="M226.271 160.817a1 1 0 0 1 1.8.63 3.64 3.64 0 0 1-1.1 3.12c-.8.69-1.6.4-1.8-.63a3.66 3.66 0 0 1 1.1-3.12z"
        fill="#004e89"
      />
      <path
        data-name="Path 294"
        d="M226.271 160.817a1 1 0 0 1 1.8.63 3.64 3.64 0 0 1-1.1 3.12c-.8.69-1.6.4-1.8-.63a3.66 3.66 0 0 1 1.1-3.12z"
      />
      <path
        data-name="Path 295"
        d="M156.631 230.057v1.8c-1.62-.69-3.73-.46-6 .88-5.09 2.94-9.22 10.09-9.22 16 0 2.74.9 4.72 2.38 5.75a14.726 14.726 0 0 1-1.29.82l-.47.24-1.41 5.31-8.16-4.55c-2.58-1.49-4.17-4.67-4.17-9.21 0-9.07 6.36-20.09 14.21-24.63 3.88-2.24 7.39-2.47 10-1l8 4.48.17.1z"
        fill="#455a64"
      />
      <path
        data-name="Path 296"
        d="m132.291 214.187-6.09 21.11 12.19 7.04 6.31-20.98z"
        fill="#004e89"
      />
      <path
        data-name="Path 297"
        d="m132.291 214.187-6.09 21.11 12.19 7.04 6.31-20.98z"
        opacity={0.2}
      />
      <path
        data-name="Path 298"
        d="M132.291 214.187s6.91 5.87 12.41 7.17c7.22 1.75 18.7 1.94 22.77 2.22 6.22.43 10.92.42 13.12-.37 2.85-1 10.51-5.45 12.63-6.67 4.06-2.34 5.79-11.55-5.4-14.07a113 113 0 0 0-23-2.35z"
        fill="#004e89"
      />
      <path
        data-name="Path 299"
        d="M201.201 211.487a11 11 0 0 0-.7 2.71 6.11 6.11 0 0 0-.22 3 6 6 0 0 0 3.37 2.63 7.57 7.57 0 0 0 5.93-1.15c2.07-1.24 3.63-2.74 6.57-3.58a14.78 14.78 0 0 0 5.8-2.63c1.63-1.31 3.81-4.93.69-5.77s-9.9 2.12-16.08 2.39z"
        fill="#37474f"
      />
      <path
        data-name="Path 300"
        d="M201.201 207.627v4.62a3.079 3.079 0 0 0 3.94 1.79 4.11 4.11 0 0 0 2.64-3.66l.18-2.75z"
        fill="#ffa8a7"
      />
      <path
        data-name="Path 301"
        d="M210.001 179.057c0 5.75-1.62 30.61-1.62 30.61s-2.35 3-7.46 0l-1.72-27.78z"
        fill="#37474f"
      />
      <path
        data-name="Path 302"
        d="m183.201 203.557 16-9.22a4.06 4.06 0 0 0 0-7.19l-11.92-6.88a4.79 4.79 0 0 0-4.33 0l-16 9.22a4.07 4.07 0 0 0 0 7.19l11.92 6.88a4.79 4.79 0 0 0 4.33 0z"
        fill="#004e89"
      />
      <path
        data-name="Path 303"
        d="m183.201 203.557 16-9.22a4.06 4.06 0 0 0 0-7.19l-11.92-6.88a4.79 4.79 0 0 0-4.33 0l-16 9.22a4.07 4.07 0 0 0 0 7.19l11.92 6.88a4.79 4.79 0 0 0 4.33 0z"
        fill="#fff"
      />
      <path
        data-name="Path 304"
        d="m166.951 191.987 11.92 6.88a4.79 4.79 0 0 0 4.33 0l16-9.22a1.32 1.32 0 0 0 0-2.5l-11.92-6.88a4.79 4.79 0 0 0-4.33 0l-16 9.22a1.32 1.32 0 0 0 0 2.5z"
        fill="#004e89"
      />
      <path
        data-name="Path 305"
        d="m176.701 130.787-2.03 8.37-8.72.53 1.88-11.6z"
        fill="#ffa8a7"
      />
      <path
        data-name="Path 306"
        d="M185.201 124.487s1 3.54.59 3.74a6.3 6.3 0 0 1-1.9 0z"
        fill="#f28f8f"
      />
      <path
        data-name="Path 307"
        d="M183.531 113.187c1.4 1.65 2.68 4.36 1.69 11-1.08 7.26-2.42 8.1-3.5 8.8-1.41.92-7.05.43-8.64-.73-1.91-1.39-4.86-5.8-5.56-10.15-.82-5.11.89-10.78 4.65-11.75 5.29-1.37 10.17 1.43 11.36 2.83z"
        fill="#ffa8a7"
      />
      <path
        data-name="Path 308"
        d="M166.951 130.437c1.35 1.4 4.12 1.78 5.86 2.1s7.09.67 8.67-3.14.5-6.55 2.44-7.47 3.88-1.52 4.39-2 .52-6.75-4.69-10-13.19-4-18.44 2.59c-3.98 5.09-2.35 13.62 1.77 17.92z"
        fill="#455a64"
      />
      <path
        data-name="Path 309"
        d="M172.201 132.587c1.35.12 5.62-.55 6.91-2.31s1.81-4.91 2-6.56a2.86 2.86 0 0 1 2.31-2.73c1.29-.41 4.14-1.81 4.14-1.81v-.78a22 22 0 0 1 2.76 0c.6.11.27 1-1.25 1.77s-5.69 2.26-6.33 2.77-.94 2.66-1.45 4.95-2.09 5.71-9.09 4.7z"
        fill="#37474f"
      />
      <path
        data-name="Path 310"
        d="m184.201 142.057 5-2.87c.48-1.45-.29-2.18-1.65-2.09s-.94-1.23-1.77-1.6c-1.09-.5-1.71-.22-2.2-.45s-.7-.81-1.34-.88c-2.86-.27-4.81 3.81-4.81 3.81z"
        fill="#f28f8f"
      />
      <path
        data-name="Path 311"
        d="M150.261 178.777s22.09-10.95 25.94-12.72 9.89-4.34 13.1-4.34a5.23 5.23 0 0 1 4.79 4.9 36.771 36.771 0 0 1-.33 5.77 135.867 135.867 0 0 1-18.56 4.67c-9.19 1.6-24.94 1.72-24.94 1.72z"
        fill="#37474f"
      />
      <path
        data-name="Path 312"
        d="M152.661 172.447c0 4.54-.43 9.26 1.46 12.58s12.13 11 21.77 9.39c6.3-1.06 25-9.67 25-9.67s9.07-3.33 9.11-5.7c.06-3.49-.18-8.41-8.09-8.2-7.21.19-28.48 4.63-28.48 4.63z"
        fill="#455a64"
      />
      <path
        data-name="Path 313"
        d="M158.831 136.477c3.42-3.57 5.73-3.87 7.37-3.43a57.56 57.56 0 0 1 10.89 4.36s-1.32 12.78 4.93 16.46v19.92s-.19 2.16-2.91 4.31c-2.4 1.89-7.83 3.8-14.31 3.26-7.7-.64-12.14-8.91-12.14-8.91s-.2-16.29.89-22.76c1.28-7.55 4.1-11.97 5.28-13.21z"
        fill="#455a64"
      />
      <path
        data-name="Path 314"
        d="M182.021 154.837s-3.78-3-4.93-6.9c0 0 .45 6.12 4.93 9.22z"
        fill="#37474f"
      />
      <path
        data-name="Path 315"
        d="M217.011 164.237a7.81 7.81 0 0 0 5-1.55s1.74-3.38 3.88-3.09c0 0 .38-1.44-.5-1.91s-1.26-.34-1.62-.84-.4-1.43-2-1.43c-.66 0-.57-.47-2.17-.66s-3.18 3.5-4.35 3.5z"
        fill="#ffa8a7"
      />
      <path
        data-name="Path 316"
        d="M177.091 137.407c5.49.85 7.48 2.48 9.48 4.33s14.24 14.13 14.24 14.13l14.82 2.13a5 5 0 0 1 1.64 2.44c.6 1.74.06 3.64-.51 4.09 0 0-14.7.88-17.59.33s-17.2-10.05-17.2-10.05a12.82 12.82 0 0 1-5-8.63c-.93-6.03.12-8.77.12-8.77z"
        fill="#455a64"
      />
      <path
        data-name="Path 317"
        d="m163.911 190.227-22.71 27.72a6.328 6.328 0 0 1-.76.05 5.44 5.44 0 0 1-2.59-.61l-19.18-11.08-1.3-.75c-.71-.41-1.3-2.09-1.3-3.75v-4.67a27.332 27.332 0 0 1 13.664-23.672l22.9-13.218 24.37 14.07z"
        fill="#004e89"
      />
      <g data-name="Group 231">
        <path
          data-name="Path 318"
          d="m163.911 190.227-22.71 27.72a6.328 6.328 0 0 1-.76.05 5.44 5.44 0 0 1-2.59-.61l-19.18-11.08-1.3-.75c-.71-.41-1.3-2.09-1.3-3.75v-4.67a27.332 27.332 0 0 1 13.664-23.672l22.9-13.218 24.37 14.07z"
          opacity={0.1}
        />
      </g>
      <path
        data-name="Path 319"
        d="m156.081 199.787 20.89-25.46-24.37-14.07-22.89 13.21a27.28 27.28 0 0 0-7.7 6.65z"
        fill="#004e89"
      />
      <path
        data-name="Path 320"
        d="M176.971 194.767c0 1.66-.58 3.34-1.3 3.75l-1.3.75-31.36 18.11a5 5 0 0 1-1.84.57 6.328 6.328 0 0 1-.76.05v-6.79a27.322 27.322 0 0 1 13.674-23.672l2.586-1.488 20.3-11.73z"
        fill="#004e89"
      />
      <g data-name="Group 232">
        <path
          data-name="Path 321"
          d="M176.971 194.767c0 1.66-.58 3.34-1.3 3.75l-1.3.75-31.36 18.11a5 5 0 0 1-1.84.57 6.328 6.328 0 0 1-.76.05v-6.79a27.322 27.322 0 0 1 13.674-23.672l2.586-1.488 20.3-11.73z"
          opacity={0.2}
        />
      </g>
      <path
        data-name="Path 322"
        d="m183.701 229.067 12.27-.04 17.56-10.14-17.2 12.43-12.95.18z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 323"
        d="M153.911 246.527s22.12-8.34 24-9.32a48 48 0 0 0 6.26-4.57 2.66 2.66 0 0 0-.33-3.57s-9-.41-11.31.3c-1.29.39-22.11 7.07-22.11 7.07z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 324"
        d="M152.921 237.207c-1.83-1.57-3.67-.92-4.12 1.44a8.35 8.35 0 0 0 2.51 7.13c1.83 1.57 3.67.93 4.12-1.44a8.36 8.36 0 0 0-2.51-7.13z"
        fill="#455a64"
      />
    </g>
  </Svg>
);

export default IconDeliveryMan;
