import {
  TimelineItem, TimelineSeparator, TimelineConnector,
  TimelineDot, TimelineContent, Timeline, timelineClasses,
} from '@mui/lab';
import {
  Box,
  Grid, styled, Typography, TypographyProps, useTheme,
} from '@mui/material';
import {
  CloudPrint,
  TruckDelivery,
} from 'mdi-material-ui';
import { RocketLaunch } from '@mui/icons-material';
import { useState, useCallback, SyntheticEvent } from 'react';
import IconDeliveryMan from '../components/icons/IconDeliveryMan';
import Container from '../components/app/Container';
import Accordion from '../components/app/Accordion';
import AccordionSummary from '../components/app/AccordionSummary';
import AccordionDetails from '../components/app/AccordionDetails';
import Button from '../components/app/Button';
import Testimonials from '../components/app/Testimonials';

const timelines = [
  {
    icon: CloudPrint,
    title: 'Create a print distribution',
    description: `Create a print distribution publication in your Litireso account.
              It's free and straightforward.
              Your print distribution publication will be in review
              and not available for purchase in the Litireso store.`,
  },
  {
    icon: TruckDelivery,
    title: 'Send the books to our warehouse',
    description: `After you've created the publication,
              we will send instructions to your registered Litireso email address on how
              to send the books to our warehouse in Lagos, Nigeria.
              We will approve your print distribution publication once we receive the books
              and it will be available for purchase immediately in the Litireso store.`,
  },
  {
    icon: RocketLaunch,
    title: 'We ship!',
    description: `Whenever your print publication is purchased in the Litireso store,
              we will ship the book to the customer with no further action from you.`,
  },
];

const faq = [
  {
    title: 'What is Print Distribution?',
    answer: 'Print distribution allows you to sell your already printed books on Litireso.',
  },
  {
    title: 'How many countries do you ship to?',
    answer: 'We currently ship to 245+ countries.',
  },
  {
    title: 'Can I use your shipping service to send my books without selling on Litireso?',
    answer: 'Yes, you can use our shipping service to send books to anywhere in the world without selling your print books on Litireso. Please call us to place an order.',
  },
  {
    title: 'How long does it take to deliver the books to the destination?',
    answer: 'Depending on the location and when an order is made, it can take 2-5 working days to get the order delivered.',
  },
  {
    title: 'Are my books safe in your warehouse?',
    answer: 'We provide the highest level of security to your books in our warehouse and they are covered by goods-in-transit insurance when they are moved from our warehouse to their final destination',
  },
  {
    title: 'Should I add the shipping fee to the price of my book?',
    answer: 'No. Litireso will charge the customer the shipping fee at the point of purchase so we do not expect you to add the shipping fee to the cost of the book. If you choose not to sell your book on Litireso, we will charge you the shipping fee.',
  },
  {
    title: 'Can I ship other things aside from books?',
    answer: 'No. The Print Distribution service can only be used to ship books, magazines, journals and/or other similar items that can be regarded as a publication.',
  },
];

const SectionTitle = styled(({ children, ...props }: TypographyProps) => (
  <Typography
    variant="h4"
    textAlign="center"
    fontWeight="600"
    {...props}
  >
    {children}
  </Typography>
))(({ theme }) => ({
  '&': {
    color: theme.palette.primary.main,
  },
}));

const PrintDistribution = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleExpanded = useCallback((name:string) => (_: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? name : false);
  }, []);

  return (
    <Box sx={{
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
    }}
    >

      <Container maxWidth="lg">
        <Grid container alignItems="center" justifyContent="space-between" columnGap={3}>
          <Grid item xs={12} md={6} marginBottom={{ xs: 5, md: 'unset' }}>
            <Typography variant="h3" component="h1" gutterBottom fontWeight="600">Last mile delivery of books to anywhere in the world</Typography>
            <Typography>
              Litireso offers competitive shipping rates and can ship to anywhere in the world
            </Typography>
          </Grid>
          <Grid xs={12} md="auto">
            <IconDeliveryMan sx={{ width: '500px', maxWidth: '100%' }} />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="md" sx={{ marginTop: theme.spacing(10) }}>
        <SectionTitle>
          How it works!
        </SectionTitle>

        <Timeline sx={{
          [timelineClasses.root]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
        >
          {timelines.map((timeline) => (
            <TimelineItem
              key={timeline.title}
              sx={{
                '&::before': {
                  flex: 'unset',
                  padding: 0,
                },
              }}
            >
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                  <timeline.icon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px' }}>
                <Typography variant="h6" component="span" fontWeight="600">
                  {timeline.title}
                </Typography>
                <Typography>
                  {timeline.description}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Container>

      <Container maxWidth="lg" sx={{ marginTop: 10 }}>
        <SectionTitle>FAQ</SectionTitle>
        <Typography gutterBottom textAlign="center" marginBottom={5}>{'We\'ve answered some frequently asked questions below'}</Typography>

        {faq.map((item, index) => (
          <Accordion
            key={item.title}
            expanded={expanded === index.toString()}
            onChange={handleExpanded(index.toString())}
          >
            <AccordionSummary>
              <Typography>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>

      <Container maxWidth="lg" sx={{ marginY: 10 }}>
        <Testimonials />
      </Container>

      <Box paddingY={12} sx={{ backgroundColor: '#002139' }}>
        <Container maxWidth="lg">
          <Typography color="white" variant="h3" fontWeight="600" marginBottom={5}>Start selling your Print books</Typography>
          <Button href="/app/dashboard/publications" size="large">Get started</Button>
        </Container>
      </Box>
    </Box>
  );
};

export default PrintDistribution;
